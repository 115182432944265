import React, { useEffect} from 'react'


const Loader = () => {
 
  return (
    <>
    <div className="cssload-preloader" >
  <div className="loader" >
    <span></span>
      <p>Loading...</p>
  </div>
</div>
    </>
  )
}

export default Loader